body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("http://bedfordmathstutor.co.uk/wp-content/uploads/2018/02/bg_g.png"); */
  /* background: linear-gradient(180deg, black, #232323); */
  background-color: #121212;
  /* background-image: url("https://www.colourbox.com/preview/2250830-maths-seamless-pattern-tile-for-endless-background-swatch-ready.jpg") */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  line-height: 1.4;
}

ul {
  padding-left: 20px;
}

.header, * {
  font-family: Lato, Arial, Helvetica, sans-serif;
}